<template>
  <div
    ref="scrollContainer"
    class="scrollbar-hide overflow-auto"
    @mousewheel="onMouseWheel"
  >
    <slot />
  </div>
</template>

<script setup>
import { ref } from "vue"

const scrollContainer = ref()

function onMouseWheel(event) {
  scrollContainer.value.scrollLeft += event.deltaY / 2
}

function resetPosition() {
  if (scrollContainer.value) {
    scrollContainer.value.scrollTo(0, 0)
  }
}

defineExpose({
  resetPosition,
})
</script>

<template>
  <MeteringPointCard :metering-point>
    <LoadingWrapper :error="loadingFailed" :loading="loading">
      <!-- Stat row -->
      <div v-if="showStats" class="flex justify-between">
        <!-- Stat expected revenue -->
        <StatComponent
          label="Totaal verwachte omzet"
          :value="totalExpectedRevenue"
          :precision="2"
          format="€ _"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="money-euro-circle" filled class="fill-finance" />
          </template>
          <template #description="{ label }">
            {{ label }}
          </template>
        </StatComponent>
      </div>
      <!-- Chart -->
      <div class="h-[350px]">
        <TimeseriesChart
          chart-type="bar"
          :series="series"
          :axes="axes"
          :data="chartData"
          :x-limits="[dateRange[0], endOfDay(dateRange[1])]"
          :time-unit="step.unit"
        >
          <template v-if="meteringPoint.ean" #legend-annotation>
            <BaseBadge>
              <span class="font-bold">EAN</span>&nbsp;{{ meteringPoint.ean }}
            </BaseBadge>
          </template>
          <template #left-axis-title="{ title }">
            <BaseIcon
              name="money-euro-circle"
              filled
              class="fill-finance text-lg"
            />
            {{ title }}
          </template>
        </TimeseriesChart>
      </div>
    </LoadingWrapper>
  </MeteringPointCard>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { differenceInCalendarDays, endOfDay } from "date-fns"
import { colors } from "@/services/themeService.js"
import { debounce, DEBOUNCE_DELAY } from "@/helpers/debounce.js"
import useNotificationStore from "@/stores/notificationStore.js"
import { ASSET } from "@/stores/repower/meteringPointStoreFactories.js"
import {
  BaseIcon,
  BaseBadge,
  LoadingWrapper,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import MeteringPointCard from "@/components/panels/MeteringPointCard.vue"
import StatComponent from "@/components/information/StatComponent.vue"

const props = defineProps({
  meteringPoint: {
    type: Object,
    default: null,
  },
  dateRange: {
    type: Array,
    required: true,
  },
  showStats: Boolean,
})

const notificationStore = useNotificationStore()
const chartData = ref({})
const chartLabels = ref({})
const loading = ref(false)
const loadingFailed = ref(false)
const totalExpectedRevenue = ref()

const axes = {
  finance: {
    title: "Verwachte omzet",
    format: "€ _",
    precision: 0,
  },
}

const series = computed(() => ({
  imbalance: {
    label: chartLabels.value["imbalance"],
    axis: "finance",
    colorScale: colors.finance,
  },
}))

const step = computed(() => {
  const days =
    1 + differenceInCalendarDays(props.dateRange[1], props.dateRange[0])
  if (days < 2) {
    return { freq: "15min", unit: "hour" }
  } else if (days < 4) {
    return { freq: "1h", unit: "hour" }
  } else {
    return { freq: "1D", unit: "day" }
  }
})

function onNewData(data) {
  chartData.value = data.data
  totalExpectedRevenue.value = data.totals.imbalance
  chartLabels.value = {}
  for (const key in data.meta) {
    chartLabels.value[key] = data.meta[key].label_nl
  }
  loading.value = false
}

function onLoadDataFailed(error) {
  loadingFailed.value = true
  notificationStore.pushError(
    "Fout bij het ophalen van verwachte omzet",
    `De verwachte omzet van een asset konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-asset-calculated-revenue-error",
  )
}

let debouncedLoadCalculatedRevenue = null

watch(
  [() => props.meteringPoint, () => props.dateRange],
  ([meteringPoint, dateRange]) => {
    if (meteringPoint?.role !== ASSET) {
      return
    }
    debouncedLoadCalculatedRevenue = debounce(
      meteringPoint.loadCalculatedRevenue,
      DEBOUNCE_DELAY,
    )
    loading.value = true
    loadingFailed.value = false
    chartData.value = {}
    debouncedLoadCalculatedRevenue(
      step.value.freq,
      dateRange,
      onNewData,
      onLoadDataFailed,
    )
  },
  { immediate: true },
)
</script>

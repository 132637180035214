<template>
  <div class="w-full gap-4 md:flex">
    <!-- Asset selection -->
    <FilterMenuComponent
      ref="filterMenuRef"
      v-model="filterStore.currentMeteringPoint"
      :items="filterItems"
      :disabled-parents="noAllocationPoints"
      :shaded-overlay="'search' in $route.query"
      @confirmed="onFilterConfirmed"
      @cancelled="onFilterCancelled"
    >
      <template #[`POR_${filterStore.currentPortfolio?.name}`]>
        <BaseIcon name="briefcase-4" filled class="text-lg" />
        <span class="mx-2">{{ filterStore.currentPortfolio.name }}</span>
      </template>
      <!-- Undo allocation point label prefix -->
      <template
        v-for="allocationPoint in filterStore.currentPortfolioAllocationPoints"
        :key="allocationPoint.id"
        #[`AP_${allocationPoint.name}`]
      >
        {{ allocationPoint.name }}
      </template>
      <!-- Enrich asset names with status and icon -->
      <template
        v-for="asset in filterStore.currentPortfolioAssets"
        :key="asset.id"
        #[asset.name]
      >
        <StatusIndicatorComponent :asset class="size-4" />
        <span class="mx-2">{{ asset.name }}</span>
        <BaseBadge class="!bg-energy-50 !text-energy-900">
          <BaseIcon :name="asset.icon" filled />
        </BaseBadge>
      </template>
    </FilterMenuComponent>
    <!-- Date selection -->
    <DatePicker
      v-if="!noDatepicker"
      v-model="filterStore.dateRange"
      teleport="#main-layout"
      class="my-2 md:my-0 md:w-80"
    />
    <!-- Filler -->
    <div class="flex-1" />
    <!-- Mode selection -->
    <RadioButton
      v-if="!noMode"
      :initial-value="filterStore.mode"
      class="mb-2 grid grid-cols-3 md:mb-0 md:flex"
      :items="radioItems"
      @selection-changed="onModeChanged"
    >
      <template #energy="{ label }">
        <BaseIcon name="flashlight" filled class="mr-1" />
        {{ label }}
      </template>
      <template #finance="{ label }">
        <BaseIcon name="money-euro-circle" filled class="mr-1 text-lg" />
        {{ label }}
      </template>
    </RadioButton>
    <!-- Other buttons -->
    <slot />
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useFilterStore from "@/stores/filterStore.js"
import {
  BaseBadge,
  BaseIcon,
  DatePicker,
  RadioButton,
} from "@repowerednl/ui-component-library"
import FilterMenuComponent from "@/components/input/FilterMenuComponent.vue"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const props = defineProps({
  assetsFilter: {
    type: Function,
    default: null,
  },
  noAllocationPoints: Boolean,
  withPortfolio: Boolean,
  noDatepicker: Boolean,
  noMode: Boolean,
})

const route = useRoute()
const router = useRouter()
const filterStore = useFilterStore()
const filterMenuRef = ref()

const filterItems = computed(() => {
  // Prefix allocation points labels to distinguish from assets that have the
  // same name
  const allocationPointItems = filterStore.currentPortfolioAllocationPoints.map(
    (allocationPoint) => {
      return {
        label: `AP_${allocationPoint.name}`,
        value: allocationPoint,
      }
    },
  )
  const filteredAssets = props.assetsFilter
    ? filterStore.currentPortfolioAssets.filter(props.assetsFilter)
    : filterStore.currentPortfolioAssets
  const assetItems = filteredAssets.map((asset) => {
    const allocationPoint = filterStore.currentPortfolioAllocationPoints.find(
      (ap) => ap.id === asset.allocationPointId,
    )
    return {
      label: asset.name,
      value: asset,
      parent: allocationPoint ? `AP_${allocationPoint.name}` : null,
    }
  })
  if (props.withPortfolio && filterStore.currentPortfolio) {
    return [
      {
        label: `POR_${filterStore.currentPortfolio.name}`,
        value: filterStore.currentPortfolio,
      },
      ...allocationPointItems,
      ...assetItems,
    ]
  } else {
    return [...allocationPointItems, ...assetItems]
  }
})

const radioItems = computed(() => [
  { label: "Energie", value: "energy" },
  { label: "Financieel", value: "finance" },
  { label: "Beide", value: "both" },
])

function onFilterConfirmed() {
  router.replace({ query: null })
}

function onFilterCancelled() {
  router.replace({ query: null })
}

function onModeChanged(selection) {
  filterStore.setMode(selection)
}

/**
 * Open the menu when the 'search' URL query is present
 */
watch(
  [() => route.query, () => filterMenuRef.value],
  ([query, menuRef]) => {
    if (menuRef && "search" in query) {
      filterMenuRef.value.openMenu()
    }
  },
  { immediate: true },
)
</script>

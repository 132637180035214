import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "./index.css"
import "@repowerednl/ui-component-library/style.css"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { setDefaultOptions } from "date-fns"
import { nl } from "date-fns/locale"
import * as Sentry from "@sentry/vue"

// Set up Pinia and persisted stores
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// Set date-fns locale to Dutch
setDefaultOptions({ locale: nl })

// Create the app
const app = createApp(App)

// Sentry
Sentry.init({
  app,
  dsn: "https://24ee3d9533caba35f837842f90bb095e@o4504639387467776.ingest.sentry.io/4506818013167616",
  // eslint-disable-next-line no-undef
  release: `repower_frontend@${__APP_VERSION__}`,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Mount the app with plugins
app.use(router).use(pinia).mount("#app")

import { defineStore } from "pinia"
import { postApiJson, fetchApiCatch } from "@/services/repowerAPIService.js"
import HTTP_STATUS from "@/helpers/httpStatusHelper"
import useNotificationStore from "@/stores/notificationStore.js"
import useSessionStore from "@/stores/sessionStore.js"
import useChangelogStore from "@/stores/changelogStore.js"
import useMeteringPointsStore from "@/stores/repower/meteringPointsStore.js"
import useFilterStore from "@/stores/filterStore.js"
import useDashboardStore from "@/stores/dashboardStore.js"

export default defineStore("userStore", {
  state: () => ({
    fullName: "",
    email: "",
  }),
  actions: {
    setUser(fullName, email) {
      this.fullName = fullName
      this.email = email
    },
    loadMeteringPoints() {
      // Set up notifications
      const onLoadFail = (error) => {
        useNotificationStore().pushError(
          "Fout bij het laden van assets",
          `Assets of bovenliggende allocatiepunten en portfolios konden niet
          worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
          "load-metering-points-fail",
        )
      }
      // Load assets and portfolios
      const meteringPointsStore = useMeteringPointsStore()
      meteringPointsStore.loadPortfolios(() => {
        const defaultPortfolio = meteringPointsStore.portfolios[0]
        if (defaultPortfolio) {
          useFilterStore().currentPortfolio = defaultPortfolio
        }
      }, onLoadFail)
      meteringPointsStore.loadAllocationPoints(null, onLoadFail)
      meteringPointsStore.loadAssets(null, onLoadFail)
    },
    login(email, password, onSuccess, onUnknown, onFail) {
      // Attempt login
      postApiJson("/users/login/", { email, password })
        .then((data) => {
          this.setUser(data.user_full_name, data.user_email)
          useSessionStore().setAuthenticated(true)
          useChangelogStore().loadDateUpdated()
          this.loadMeteringPoints()
          onSuccess?.()
        })
        .catch((error) => {
          if (error.code === HTTP_STATUS.BAD_REQUEST) {
            onUnknown?.(error)
          } else {
            onFail?.(error)
          }
        })
    },
    logout(onSuccess, onFail) {
      const meteringPointsStore = useMeteringPointsStore()
      fetchApiCatch("/users/logout/")
        .then(() => {
          // Reset session
          const sessionStore = useSessionStore()
          sessionStore.setAuthenticated(false)
          sessionStore.requestCsrfToken()
          // Reset other stores
          useChangelogStore().$reset()
          meteringPointsStore.$reset()
          meteringPointsStore.$reset()
          useFilterStore().$reset()
          useDashboardStore().$reset()
          this.$reset()
          // Run callback
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    changeFullName(fullName, onSuccess, onFail) {
      postApiJson(
        "/users/change-full-name/",
        { full_name: fullName },
        { method: "PUT" },
      )
        .then(() => {
          this.fullName = fullName
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    requestEmailChange(newEmail, onSuccess, onFail) {
      postApiJson(
        "/users/request-email-change/",
        { new_email: newEmail },
        { method: "POST" },
      )
        .then(() => {
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    changeEmail(newEmail, token, password, onSuccess, onFail) {
      postApiJson(
        "/users/change-email/",
        { token, password },
        { method: "PUT" },
      )
        .then(() => {
          this.email = newEmail
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    changePassword(currentPassword, newPassword, onSuccess, onFail) {
      postApiJson(
        "/users/change-password/",
        { current_password: currentPassword, new_password: newPassword },
        { method: "PUT" },
      )
        .then(() => {
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
  },
  persist: true,
})

export const DEBOUNCE_DELAY = 200

export function debounce(func, delay) {
  let timerId

  return function (...args) {
    const context = this

    if (timerId) {
      clearTimeout(timerId)
    }

    timerId = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}

import { defineStore } from "pinia"
import { format, addDays } from "date-fns"
import {
  fetchApiJson,
  makeDataFetchObject,
  prepareDataFetch,
  handleDataFetchPromise,
} from "@/services/repowerAPIService.js"

/* -------------------------------- Constants ------------------------------- */

export const PORTFOLIO = 1
export const ALLOCATION_POINT = 2
export const ASSET = 3

/* --------------------------------- Methods -------------------------------- */

function setMeteringPoint(asset) {
  this.properties = asset
  this.status = asset.status
}

function loadStatus(onSuccess, onFail) {
  fetchApiJson(`${this.endpoint}/status/`)
    .then((data) => {
      this.status = data
      onSuccess?.(data)
    })
    .catch((error) => {
      this.status = null
      onFail?.(error)
    })
}

function returnCache(getExport, isResultsTable) {
  if (getExport) {
    return this.exportData
  }
  return isResultsTable ? this.tableData : this.recordStreamCollections
}

function loadMeasurements(freq, dateRange, getExport, onSuccess, onFail) {
  const cache = this.returnCache(getExport)
  const { success, params, uid } = prepareDataFetch(
    cache,
    freq,
    dateRange,
    null,
    onSuccess,
  )
  if (success) {
    const measurementTypes = this.type === "Battery" ? "power,soc" : "power"
    const promise = fetchApiJson(
      `${this.endpoint}/measurements/${getExport ? "export/" : ""}` +
        `?${params}&measurement_type=${measurementTypes}`,
    )
    handleDataFetchPromise(cache, uid, promise, params, onSuccess, onFail)
  }
}

function loadCalculatedRevenue(freq, dateRange, onSuccess, onFail) {
  // #TODO(REP-2961- Refactor the different ...fetchData functions)
  const { success, params, uid } = prepareDataFetch(
    this.calculatedRevenue,
    freq,
    dateRange,
    null,
    onSuccess,
  )
  if (success) {
    const promise = fetchApiJson(
      `${this.endpoint}/calculated-revenue/?${params}&revenue_types=imbalance`,
    )
    handleDataFetchPromise(
      this.calculatedRevenue,
      uid,
      promise,
      params,
      onSuccess,
      onFail,
    )
  }
}

function loadBatteryCycles(dateRange, onSuccess, onFail) {
  const params = new URLSearchParams({
    date_start: format(dateRange[0], "yyyy-MM-dd"),
    date_end: format(addDays(dateRange[1], 1), "yyyy-MM-dd"),
  }).toString()
  fetchApiJson(`${this.endpoint}/kpis/battery-cycles/?${params}`)
    .then((data) => {
      onSuccess?.(data)
    })
    .catch((error) => {
      onFail?.(error)
    })
}

function loadRecordStreamCollection(
  freq,
  dateRange,
  getExport,
  onSuccess,
  onFail,
  isResultsTable = false,
) {
  const cache = this.returnCache(getExport, isResultsTable)
  const { success, params, uid } = prepareDataFetch(
    cache,
    freq,
    dateRange,
    false,
    onSuccess,
  )
  if (success) {
    const promise = fetchApiJson(
      `${this.endpoint}/record-stream-collections/`,
    ).then((collections) => {
      if (collections.length === 0) {
        throw new Error("No collections present")
      }
      return fetchApiJson(
        `/v2/record-streams/${collections[0].collection_id}/` +
          `${getExport ? "export/" : ""}?${params}`,
      )
    })
    handleDataFetchPromise(cache, uid, promise, params, onSuccess, onFail)
  }
}

function loadFcrAllocations(dateRange, onSuccess, onFail) {
  const { success, params, uid } = prepareDataFetch(
    this.fcrAllocations,
    null,
    dateRange,
    null,
    onSuccess,
  )
  if (success) {
    const promise = fetchApiJson(`${this.endpoint}/fcr-allocations/?${params}`)
    handleDataFetchPromise(
      this.fcrAllocations,
      uid,
      promise,
      params,
      onSuccess,
      onFail,
    )
  }
}

function loadSetpoints(dateRange, onSuccess, onFail) {
  const { success, params, uid } = prepareDataFetch(
    this.setpoints,
    null,
    dateRange,
    null,
    onSuccess,
  )
  if (success) {
    const promise = fetchApiJson(`${this.endpoint}/setpoints/?${params}`)
    handleDataFetchPromise(
      this.setpoints,
      uid,
      promise,
      params,
      onSuccess,
      onFail,
    )
  }
}

/* ---------------------------- Store definitions --------------------------- */

export function usePortfolioStoreFactory(id) {
  return defineStore(`PORTFOLIO-${id}`, {
    state: () => ({
      role: PORTFOLIO,
      endpoint: `/v2/portfolios/${id}`,
      properties: {},
      recordStreamCollections: makeDataFetchObject(),
      tableData: makeDataFetchObject(),
      exportData: makeDataFetchObject(),
    }),
    getters: {
      id: (state) => state.properties?.id,
      name: (state) => state.properties?.name,
    },
    actions: {
      setMeteringPoint,
      loadRecordStreamCollection,
      returnCache,
    },
  })() // <-- Note the () here to call the function
}

export function useAllocationPointStoreFactory(id) {
  return defineStore(`ALLOCATION-POINT-${id}`, {
    state: () => ({
      role: ALLOCATION_POINT,
      endpoint: `/v2/allocation-points/${id}`,
      properties: {},
      recordStreamCollections: makeDataFetchObject(),
      tableData: makeDataFetchObject(),
      exportData: makeDataFetchObject(),
    }),
    getters: {
      id: (state) => state.properties?.id,
      name: (state) => state.properties?.name,
      portfolioId: (state) => state.properties?.portfolio_id,
    },
    actions: {
      setMeteringPoint,
      loadRecordStreamCollection,
      returnCache,
    },
  })() // <-- Note the () here to call the function
}

export function useAssetStoreFactory(id) {
  return defineStore(`ASSET-${id}`, {
    state: () => ({
      role: ASSET,
      endpoint: `/v2/assets/${id}`,
      properties: {},
      status: null,
      measurements: makeDataFetchObject(),
      calculatedRevenue: makeDataFetchObject(),
      recordStreamCollections: makeDataFetchObject(),
      tableData: makeDataFetchObject(),
      fcrAllocations: makeDataFetchObject(),
      setpoints: makeDataFetchObject(),
      exportData: makeDataFetchObject(),
    }),
    getters: {
      id: (state) => state.properties?.id,
      name: (state) => state.properties?.name,
      type: (state) => state.properties?.type,
      portfolioId: (state) => state.properties?.portfolio_id,
      allocationPointId: (state) => state.properties?.allocation_point_id,
      ean: (state) => state.properties?.allocation_point_ean,
      icon: (state) => {
        switch (state.properties?.type) {
          case "Battery":
            return "battery"
          case "Consumer":
            return "database"
          default:
            return "sun"
        }
      },
    },
    actions: {
      setMeteringPoint,
      loadStatus,
      loadMeasurements,
      loadCalculatedRevenue,
      loadBatteryCycles,
      loadRecordStreamCollection,
      loadFcrAllocations,
      loadSetpoints,
      returnCache,
    },
  })() // <-- Note the () here to call the function
}

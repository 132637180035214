import { format, addDays } from "date-fns"
import useSessionStore from "@/stores/sessionStore"
import useUid from "@/composables/uid.js"
import HTTP_STATUS from "@/helpers/httpStatusHelper.js"

const BASE_URL = `${import.meta.env.VITE_API_URL}/api`

export function fetchApi(path, options = {}) {
  const url = `${BASE_URL}${path}`
  return fetch(url, options)
}

export function fetchApiCatch(path, options, toJSON = false) {
  return fetchApi(path, options).then((response) => {
    if (response.status === HTTP_STATUS.OK) {
      return toJSON ? response.json() : response
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export function fetchApiJson(path, options = {}) {
  return fetchApiCatch(path, options, true)
}

export function postApi(path, body, options) {
  const sessionStore = useSessionStore()
  return fetchApi(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": sessionStore.csrfToken,
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
    ...options,
  })
}

export function postApiCatch(path, body, options, toJSON = false) {
  return postApi(path, body, options).then((response) => {
    if (response.status === HTTP_STATUS.OK) {
      return toJSON ? response.json() : response
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export function postApiJson(path, body, options) {
  return postApiCatch(path, body, options, true)
}

export function makeDataFetchObject() {
  return {
    params: null,
    data: null,
    uid: null,
  }
}

export function prepareDataFetch(cache, freq, dateRange, isFinal, onSuccess) {
  const paramsObj = new URLSearchParams({
    freq,
    date_start: format(dateRange[0], "yyyy-MM-dd"),
    date_end: format(addDays(dateRange[1], 1), "yyyy-MM-dd"),
    is_final: isFinal,
  })
  paramsObj.delete("freq", null)
  paramsObj.delete("is_final", null)
  const params = paramsObj.toString()
  // Handle the cases where cache is undefined (e.g: getExport = true)
  if (!cache) {
    cache = { uid: useUid(), data: null, params: null }
  } else {
    cache.uid = useUid()
  }

  if (cache && cache.data && cache.params === params) {
    if (onSuccess) {
      onSuccess(cache.data)
    }
    return { success: false }
  }
  return { success: true, params, uid: cache.uid }
}

export function handleDataFetchPromise(
  cache,
  uid,
  promise,
  params,
  onSuccess,
  onFail,
) {
  if (cache) {
    cache.data = null
  }
  promise
    .then((data) => {
      if (uid !== cache.uid) {
        return
      }
      if (cache) {
        cache.data = data
        cache.params = params
      }
      onSuccess?.(data)
    })
    .catch((error) => {
      onFail?.(error)
    })
}

import { defineStore } from "pinia"
import {
  fetchApiJson,
  makeDataFetchObject,
  prepareDataFetch,
  handleDataFetchPromise,
} from "@/services/repowerAPIService.js"

function loadPrices(cache, slug, dateRange, onSuccess, onFail) {
  const { success, params, uid } = prepareDataFetch(
    cache,
    null,
    dateRange,
    null,
    onSuccess,
  )
  if (success) {
    const promise = fetchApiJson(`/v2/market-prices/${slug}/?${params}`)
    handleDataFetchPromise(cache, uid, promise, params, onSuccess, onFail)
  }
}

export default defineStore("marketPricesStore", {
  state: () => ({
    loading: false,
    dayAheadPrices: makeDataFetchObject(),
    balanceDeltaPrices: makeDataFetchObject(),
  }),
  actions: {
    loadDayAheadPrices(dateRange, onSuccess, onFail) {
      loadPrices(this.dayAheadPrices, "day-ahead", dateRange, onSuccess, onFail)
    },
    loadBalanceDeltaPrices(dateRange, onSuccess, onFail) {
      loadPrices(
        this.balanceDeltaPrices,
        "balance-delta",
        dateRange,
        onSuccess,
        onFail,
      )
    },
  },
})

<template>
  <div class="flex flex-col gap-4">
    <!-- Chart panel -->
    <AssetExpectedRevenuePanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      show-stats
    />
  </div>
</template>

<script setup>
import { startOfToday } from "date-fns"
import useFilterStore from "@/stores/filterStore.js"
import { ASSET } from "@/stores/repower/meteringPointStoreFactories.js"
import AssetExpectedRevenuePanel from "@/components/panels/AssetExpectedRevenuePanel.vue"

const filterStore = useFilterStore()

if (filterStore.currentMeteringPoint?.role !== ASSET) {
  filterStore.currentMeteringPoint = null
  filterStore.dateRange = [startOfToday(), startOfToday()]
}
</script>

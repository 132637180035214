<template>
  <BaseCard class="flex h-[500px] flex-col gap-2 p-4 md:p-6">
    <!-- Header -->
    <div>
      <TextSubtitle>Marktprijzen (Day ahead)</TextSubtitle>
      <TextDescription>Huidige prijs: € {{ currentPrice }}</TextDescription>
    </div>
    <LoadingWrapper :error="loadingFailed" :loading="loading">
      <!-- Stat row -->
      <div class="flex items-start justify-between">
        <!-- Stat average -->
        <StatComponent
          label="Gemiddelde prijs"
          :value="avgPrice"
          :precision="2"
          format="€ _"
          class="flex-1"
          small
        >
          <template #icon>
            <BaseIcon name="arrow-up-down" class="fill-finance" />
          </template>
        </StatComponent>
        <!-- Stat minimum -->
        <StatComponent
          label="Laagste prijs"
          :value="minPrice"
          :precision="2"
          format="€ _"
          class="flex-1"
          small
        >
          <template #icon>
            <BaseIcon name="arrow-down" class="fill-finance" />
          </template>
        </StatComponent>
        <!-- Stat maximum -->
        <StatComponent
          label="Hoogste prijs"
          :value="maxPrice"
          :precision="2"
          format="€ _"
          class="flex-1"
          small
        >
          <template #icon>
            <BaseIcon name="arrow-up" class="fill-finance" />
          </template>
        </StatComponent>
      </div>
      <!-- Chart -->
      <TimeseriesChart
        chart-type="bar"
        :series="series"
        :axes="axes"
        :data="chartData"
        :x-limits="[dateRange[0], endOfDay(dateRange[1])]"
      />
    </LoadingWrapper>
  </BaseCard>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import { endOfDay } from "date-fns"
import { formatNumber } from "@/services/formatterService.js"
import { colors } from "@/services/themeService.js"
import useMarketPricesStore from "@/stores/repower/marketPricesStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseCard,
  BaseIcon,
  TextSubtitle,
  TextDescription,
  LoadingWrapper,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import StatComponent from "@/components/information/StatComponent.vue"

const props = defineProps({
  dateRange: {
    type: Array,
    required: true,
  },
})

const marketPricesStore = useMarketPricesStore()
const notificationStore = useNotificationStore()
const chartData = ref({})
const loading = ref(true)
const loadingFailed = ref(false)

const series = {
  price: {
    label: "Day-ahead prijs (per MWh)",
    axis: "finance",
    colorScale: colors.finance,
  },
}

const axes = {
  finance: {
    format: "€ _",
    precision: 0,
  },
}

const currentPrice = computed(() => {
  const { data } = marketPricesStore.dayAheadPrices
  return data ? formatNumber(Object.values(data).slice(-1)[0].price, 2) : "-"
})

const avgPrice = computed(() => {
  const { data } = marketPricesStore.dayAheadPrices
  if (!data) return NaN
  const prices = Object.values(data).map((value) => value.price)
  return prices.reduce((total, price) => total + price, 0) / prices.length
})

const minPrice = computed(() => {
  const { data } = marketPricesStore.dayAheadPrices
  if (!data) return NaN
  const prices = Object.values(data).map((value) => value.price)
  return Math.min(...prices)
})

const maxPrice = computed(() => {
  const { data } = marketPricesStore.dayAheadPrices
  if (!data) return NaN
  const prices = Object.values(data).map((value) => value.price)
  return Math.max(...prices)
})

function onNewPrices(data) {
  chartData.value = data
  loading.value = false
}

function onLoadPricesFailed(error) {
  loadingFailed.value = true
  notificationStore.pushError(
    "Fout bij het ophalen van day ahead prijzen",
    `De day ahead prijzen konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-dayahead-market-error",
  )
}

onMounted(() => {
  marketPricesStore.loadDayAheadPrices(
    props.dateRange,
    onNewPrices,
    onLoadPricesFailed,
  )
})
</script>

<template>
  <component
    :is="noTooltip ? 'div' : BaseTooltip"
    :description="$p(descriptionKey)"
  >
    <div class="relative" v-bind="$attrs" :class="statusColor[status]">
      <div class="h-full w-full rounded-full bg-current opacity-25" />
      <div
        class="absolute left-1/4 top-1/4 h-1/2 w-1/2 rounded-full bg-current"
      />
    </div>
  </component>
</template>

<script setup>
import { computed } from "vue"
import $p from "@/services/phraseService.js"
import { BaseTooltip } from "@repowerednl/ui-component-library"

const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
  noTooltip: Boolean,
})

defineOptions({
  inheritAttrs: false,
})

const statusColor = {
  unknown: "text-gray-200",
  Offline: "text-gray-400",
  Online: "text-finance",
  "Active steering": "text-primary",
  Error: "text-danger",
}

const status = computed(() => {
  return props.asset.status?.status ?? "unknown"
})

const descriptionKey = computed(() => {
  return props.asset.status?.description_key ?? "status-unknown"
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <!-- Chart panel -->
    <AssetChartPanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      show-stats
      show-annotations
    />
  </div>
</template>

<script setup>
import useFilterStore from "@/stores/filterStore.js"
import AssetChartPanel from "@/components/panels/AssetChartPanel.vue"

const filterStore = useFilterStore()
</script>
